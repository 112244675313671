<template>
  <div class="add_bank_page_wrap" >
    <div class="form_item" >
      <div class="form_item_label" >姓名：</div>
      <input class="form_input" placeholder="请输入姓名" v-model="form.accountHolder" />
    </div>

    <div class="form_item" >
      <div class="form_item_label" >银行卡号：</div>
      <input class="form_input" placeholder="请输入银行卡号" v-model="form.bankCardNumber" />
    </div>

    <van-button class="commit_button" :loading="submitting"  @click="handleCommit" >确认提交</van-button>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  updateBankCard
} from '@/api'
import {
  Toast
} from 'vant'
export default {
  name: 'addBank',
  data() {
    return {
      form: {
        accountHolder: '',
        bankCardNumber: ''
      },
      submitting: false,
    }
  },
  methods: {
    handleCommit() {
      const { form } = this
      if(!form.accountHolder) {
        Toast('请输入姓名')
        return false
      }

      if(!form.bankCardNumber) {
        Toast('请输入银行卡号')
        return false
      }

      this.updateBankCard()
    },
    updateBankCard() {
      let values = {...this.form}
      this.submitting = true
      updateBankCard({...values}).then(res => {
        if(res.success) {
          Toast('操作成功')
          this.$router.go(-1)
        }else {
          Toast(res.msg)
        }
      })
    },
    init() {
      let data = this.$route.query.data
      if(data) {
        data = JSON.parse(data)
        console.log(data)
        this.form = {...data}
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>
